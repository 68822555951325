import * as React from 'react'
import '../assets/css/index.css'
import '../assets/css/Section.css'
import Navigation from '../components/Navigation'; 
import { Container, Modal, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Footer from '../components/Footer';
import { GatsbySeo } from 'gatsby-plugin-next-seo';


const year = new Date().getFullYear();

// markup
const IndexPage = () => {
  return (
    <main>
      <Navigation/>
      <GatsbySeo
        title='Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Looking for a Snooker or Pool club in Norwich? Book a table before or come down to see if we have any availability'
        />
        <div className='black-section'>
        <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="pb-5 black-section">
              <StaticImage src="../assets/img/hero-image-3.jpg"
                    alt="A hero image"
                    placeholder="blurred"
                    layout="fullWidth"
                    className='no-container' />
              <h1 className='pt-3 pb-3'>
               One of the best snooker &amp; pool clubs in Norwich 
              </h1>
              <p>Pinches Cue Club went under a huge transformation in late 2021, with the snooker room of the original Clarkes Snooker and Function room being bought into by long time Norwich professional Barry Pinches.</p>
              <p>We are a private members club where on entering you will either need to pay a daily fee or join to become a member for the year.</p>
              <p>All of the old Snooker tables were removed with the installation of 5 high standard steel block tables with two being of a professional standard. 8 Ball Pool was also something added with 6 Supreme tables now in the main room and an additional 2 tables by the bar.</p>
              
            
            <StaticImage src="../assets/img/Norwich-Pinches-Cue-Club-1 (8).jpg"
              alt="A hero image"
              placeholder="blurred"
              layout="fullWidth" />
              </Col>
              </Row>
              </Container>
              </div>
        
    <div className='green-section'>
      <Container>
         <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="text-center">
          <h2 className='p-3'>
            Snooker and Pool table hire prices
            </h2>
            <p>10:00 - 12:00 | £4.00 per hour</p>
            <p>12:00 - 18:00 | £6.00 per hour</p>
            <p>18:00 - 22:00 | £7.00 per hour</p>
            <p>Snooker match tables are an additional £2 per hour, these are tables 9 and 10.</p>

            <StaticImage src="../assets/img/Norwich-Pinches-Cue-Club-1 (9).jpg"
              alt="A hero image"
              placeholder="blurred"
              layout="constrained" />
          </Col>          
            <Col lg={6} md={6} sm={12} xs={12} className="text-center">
                <h2 className='p-3'>
                  Membership Information
                </h2>
                <p>Currently membership is £10 per year from January, reduced to £5 from the beginning of September until the end of the year.</p>
                <p>We serve a wide range of refreshing soft drinks, local ales and beers on draft.</p>
                <h3 className='p-3'>
                Opening times
                </h3>
                <p>Monday: 10:00 - 23:00</p>
                <p>Tuesday: 10:00 - 23:00</p>
                <p>Wednesday: 10:00 - 23:00</p>
                <p>Thursday: 10:00 - 23:00</p>
                <p>Friday: 10:00 - 23:00</p>
                <p>Saturday: 10:00 - 23:00</p>
                <p>Sunday: 10:00 - 23:00</p>
                <p>We close 11pm Monday to Friday but sometimes we are open later in the evening, so please give us a ring to ask beforehand. We have a carpark at the back of the club, but during tournaments and events at the function room we recommend parking on Barker Street and walking to us as the carpark can get very busy.</p>
            </Col>
          </Row>
          </Container>
           <Footer/>
           </div>
           
    </main>
  )
}

export default IndexPage

